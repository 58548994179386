import axios from 'axios';
import qs from 'qs';
import { JobState } from '_/models/job/state';

export const client = axios.create({
    paramsSerializer: function(params) {
        return qs.stringify(params);
    },
    withCredentials: true
});

export const events = {
    list(options = {}) {
        return client.get('/api/events', options);
    }
};

export const jobs = {
    create(data, options = {}) {
        return client.post('/api/jobs', data, options);
    },
    list(options = {}) {
        return client.get('/api/jobs', options);
    },
    read(identity, options = {}) {
        return client.get(`/api/jobs/${identity}`, options);
    },
    wait(job, options = {}) {
        const fetcher = options.fetcher || jobs.read;
        const maxRetries = options.maxRetries || 10;
        return new Promise((resolve, reject) => {
            (function wait(n = 1) {
                if (n > maxRetries) {
                    reject(new Error('Too many retries'));
                    return;
                }

                setTimeout(() => {
                    fetcher(job.id).then((res) => {
                        if (res.data.state === JobState.ERROR) {
                            throw new Error(res.data.result.error || 'Server Error');
                        }

                        if (res.data.state === JobState.OK) {
                            resolve(res);
                            return;
                        }

                        return wait.call(this, n + 1, options);
                    }).catch(reject);
                }, (Math.pow(2, n) * 1000));
            }());
        });
    }
};

export const shopifyThemes = {
    list(options = {}) {
        return client.get('/api/shopify-themes', options);
    }
};

export const shopifyThemeSettingsDataSyncs = {
    create(data, options = {}) {
        return client.post('/api/shopify-theme-settings-data-syncs', data, options);
    },
    createRestore(identity, options = {}) {
        return client.post(`/api/shopify-theme-settings-data-syncs/${identity}/restores`, null, options);
    },
    list(options = {}) {
        return client.get('/api/shopify-theme-settings-data-syncs', options);
    },
    read(identity, options = {}) {
        return client.get(`/api/shopify-theme-settings-data-syncs/${identity}`, options);
    },
    readRestore(identity, restoreIdentity, options = {}) {
        return client.get(`/api/shopify-theme-settings-data-syncs/${identity}/restores/${restoreIdentity}`, options);
    }
};

export const users = {
    create(data, options = {}) {
        return client.post('/api/users', data, options);
    },
    destroy(identity, options = {}) {
        return client.delete(`/api/users/${identity}`, options);
    },
    getMe(options = {}) {
        return client.get('/api/users/me', options);
    },
    list(options = {}) {
        return client.get('/api/users', options);
    },
    read(identity, options = {}) {
        return client.get(`/api/users/${identity}`, options);
    },
    suspend(identity, options = {}) {
        return client.post(`/api/users/${identity}/actions/suspend`, {}, options);
    },
    unsuspend(identity, options = {}) {
        return client.delete(`/api/users/${identity}/actions/suspend`, options);
    }
};