import Bootstrap from 'bootstrap-vue';
import Vue from 'vue';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import * as toast from '_/public/scripts/utils/toast';
import './utils/sentry';

Vue.use(Bootstrap);

const app = new Vue({
    render: (h) => h(require('./login.vue').default)
}).$mount('#root');

toast.setVm(app);
