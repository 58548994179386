import { defaults, uniqueId } from 'lodash';

const CACHE: any = {
    vm: null
};

export function setVm(vm: any) {
    CACHE.vm = vm;
}

export function hide(id?: string): void {
    // @ts-ignore
    CACHE.vm.$bvToast.hide(id);
}

interface MakeDownloadToastData {
    href?: string,
    message?: string
    options?: any,
    target?: string,
    title?: string
}

export function makeDownloadToast(options: MakeDownloadToastData = {}): string {
    // @ts-ignore
    const vm = CACHE.vm;
    const id = uniqueId('toast');

    const {
        message,
        title
    } = defaults(options, {
        message: 'Download',
        options: {},
        title: 'Done'
    });

    vm.$bvToast.toast([
        vm.$createElement(
            'a',
            {
                attrs: {
                    href: options.href || '#',
                    target: options.target || '_blank'
                }
            },
            message
        )
    ], {
        appendToast: true,
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        ...options.options,
        id,
        title
    });

    return id;
}

interface MakeErrorToastData {
    message?: string
    options?: any,
    title?: string
}

export function makeErrorToast(options: MakeErrorToastData = {}): string {
    // @ts-ignore
    const vm = CACHE.vm;
    const id = uniqueId('toast');

    const {
        message,
        title
    } = defaults(options, {
        message: 'An error occurred.',
        options: {},
        title: 'Whoops!'
    });

    vm.$bvToast.toast(message, {
        appendToast: true,
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        ...options.options,
        id,
        title
    });

    return id;
}

interface MakeLoadingToastData {
    message?: string
    options?: any,
    title?: string
}

export function makeLoadingToast(options: MakeLoadingToastData = {}): string {
    // @ts-ignore
    const vm = CACHE.vm;
    const id = uniqueId('toast');

    const {
        message,
        title
    } = defaults(options, {
        message: 'Hang tight...',
        options: {},
        title: 'Loading'
    });

    vm.$bvToast.toast([
        vm.$createElement(
            'div',
            [
                vm.$createElement(
                    'b-spinner',
                    {
                        class: {
                            'mr-2': true
                        },
                        props: {
                            small: true
                        }
                    }
                ),
                vm.$createElement(
                    'span',
                    message
                )
            ]
        )
    ], {
        appendToast: true,
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'info',
        ...options.options,
        id,
        title
    });

    return id;
}

interface MakeSuccessToastData {
    message?: string
    options?: any,
    title?: string
}

export function makeSuccessToast(options: MakeSuccessToastData = {}): string {
    // @ts-ignore
    const vm = CACHE.vm;
    const id = uniqueId('toast');

    const {
        message,
        title
    } = defaults(options, {
        message: 'The action completed.',
        options: {},
        title: 'Success!'
    });

    vm.$bvToast.toast(message, {
        appendToast: true,
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        ...options.options,
        id,
        title
    });

    return id;
}