import * as toast from '_/public/scripts/utils/toast';

export function handleAxiosError(err) {
    if (err.response) {
        toast.makeErrorToast({ message: err.message });
        return;
    }
    
    if (err.request) {
        toast.makeErrorToast({ message: 'There was an error performing the request.' });
        return;
    }

    toast.makeErrorToast({ message: 'There was an unexpected error, please refresh and try again.' });
}
