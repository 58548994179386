<template>
    <b-container>
        <FlashMessages></FlashMessages>
        <b-row class="mt-5" align-h="center">
            <b-col cols="4">
                <h1 class="h4">Sign In</h1>
                <hr />
                <b-form @submit="onSubmit">
                    <b-form-group id="input-group-1" label="Email" label-for="input-1">
                        <b-form-input
                            :disabled="loading"
                            id="input-1"
                            v-model="form.email"
                            type="email"
                            required
                            placeholder="Enter email"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Password" label-for="input-2" class="mb-4">
                        <b-form-input
                            :disabled="loading"
                            id="input-2"
                            v-model="form.password"
                            required
                            placeholder="Enter password"
                            type="password"
                        ></b-form-input>
                    </b-form-group>
                    <b-button-toolbar justify>
                        <b-button :disabled="loading" type="submit" variant="primary">
                            <span v-if="!loading">Submit</span>
                            <span v-else>
                                <b-spinner small></b-spinner>
                                <span class="sr-only">Loading...</span>
                            </span>
                        </b-button>
                        <b-button variant="link" href="/reset" size="sm" class="mt-1">
                            Forgot Your Password?
                        </b-button>
                    </b-button-toolbar>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { client } from '_/public/scripts/api';
    import FlashMessages from '_/public/scripts/components/flash-messages';
    import { handleAxiosError } from '_/public/scripts/utils/handle-axios-error';
    export default {
        components: {
            FlashMessages
        },
        data() {
            return {
                form: {
                    email: null,
                    password: null
                },
                loading: false
            };
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault();
                this.loading = true;
                return client.post('/api/auth/login', {
                    email: this.form.email,
                    password: this.form.password
                }).then((res) => {
                    window.location.replace('/');
                })
                .catch(handleAxiosError.bind(this))
                .then(() => this.loading = false);
            }
        }
    };
</script>

<style lang="scss">
    $font-size-base: 0.85rem;
    @import '~bootstrap/scss/bootstrap';
    @import '~bootstrap-vue/src/index.scss';
</style>
