<template>
    <div class="mt-3">
        <div v-for="(messages, variant) in flash" :key="variant">
            <b-alert
                dismissible
                show
                :variant="variant"
                v-for="message in messages"
                :key="message"
            >{{ message }}</b-alert>
        </div>
    </div>
</template>

<script>
    import { client } from '_/public/scripts/api';
    import { handleAxiosError } from '_/public/scripts/utils/handle-axios-error';
    export default {
        created() {
            return this.fetch();
        },
        data() {
            return {
                flash: {}
            };
        },
        methods: {
            fetch() {
                return client.get('/api/utils/flash')
                    .then((res) => this.flash = res.data)
                    .catch(handleAxiosError.bind(this));
            }
        }
    };
</script>

<style lang="scss">
</style>
